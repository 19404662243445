<template>
  <div>
    <a-row>
      <a-col :xs="6">
        <a-button type="primary" @click="openGroupModal()">添加分组</a-button>
        <a-button type="primary" @click="openDiscourseModal()" style="margin-left: 15px;">新建话术</a-button>
      </a-col>
      <a-col :xs="1"></a-col>
      <a-col :xs="17">
        <a-input-search
            placeholder="请输入"
            enter-button="搜索"
            allow-clear
            @search="onSearch"
        />
      </a-col>
    </a-row>
    <div class="space-40"></div>
    <a-row style="display: flex;">
      <a-col :xs="6">
        <div class="group-wrap">
          <div class="title">分组列表</div>
          <ul class="group-box">
            <li :class="active_group_index === 'all' ? 'item active' : 'item'" @click="onSelectGroup('', 'all')">全部</li>
            <li
                v-for="(item, index) in group_list"
                :class="active_group_index === index ? 'item active' : 'item'"
                :key="index"
                @click="onSelectGroup(item.id, index)"
            >
              <span class="name">{{ item.name }}</span>
              <span class="action">
                            <a-icon class="icon green" type="edit" @click.stop="openGroupModal(item.id, item.name)"/>
                            <a-icon class="icon red" type="delete" @click.stop="delGroup(item.id)"/>
                        </span>
            </li>
          </ul>
        </div>
      </a-col>
      <a-col :xs="1"></a-col>
      <a-col :xs="17">
        <v-table ref="table" :columns="columns" :remote="remote" :query="query" :pagination="pagination"
                 :row-key="(record, index) => index">
          <template #operation="{row}">
            <a type="link" @click="openDiscourseModal(row.id)">编辑</a>
            <a type="link" class="red ml-10p" @click="delDiscourse(row.id)">删除</a>
          </template>
        </v-table>
      </a-col>
    </a-row>

    <!-- 分组 -->
    <a-modal
        :title="type_title"
        :visible="group_visible"
        :maskClosable="false"
        @cancel="handleGroupCancel"
        width="600px"
    >
      <template slot="footer">
        <a-button @click="handleGroupCancel">取消</a-button>
        <a-button type="primary" :loading="is_submit" @click="changeGroup">保存</a-button>
      </template>
      <a-form-model
          ref="group_form"
          :model="group_form"
          :rules="group_rules"
          v-bind="layout"
      >
        <a-form-model-item
            label="分组名称"
            prop="name"
        >
          <a-input v-model="group_form.name" :maxLength="6"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- 话术 -->
    <a-modal
        :title="discourse_title"
        :visible="discourse_visible"
        :maskClosable="false"
        @cancel="handleDiscourseCancel"
        width="700px"
    >
      <template slot="footer">
        <a-button type="primary" @click="addContent">添加内容</a-button>
        <a-button @click="handleDiscourseCancel">取消</a-button>
        <a-button type="primary" :loading="is_submit" @click="submitContent">保存</a-button>
      </template>
      <a-form-model
          ref="discourse_form"
          :model="discourse_form"
          :rules="discourse_rules"
          v-bind="layout"
      >
        <a-form-model-item
            label="选择分组"
            prop="group_id"
        >
          <a-select
              v-model="discourse_form.group_id"
              show-search
              placeholder="请选择分组"
              option-filter-prop="children"
              style="width: 100%"
              :filter-option="filterOption"
          >
            <a-select-option
                v-for="(item, index) in group_list"
                :key="index"
                :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
            label="话术标题"
            prop="word_title"
        >
          <a-input v-model="discourse_form.word_title" placeholder="请输入标题" :maxLength="50"/>
        </a-form-model-item>
        <a-form-model-item
            label=""
            :wrapper-col="{ span: 24, offset: 0 }"
            v-for="(item, index) in discourse_form.contents"
            :key="index"
        >
          <div class="content-wrap">
            <div class="top-box">
              <span class="title">内容{{ index + 1 }}</span>
              <span class="delete"><a-icon class="icon red" type="delete" @click="delContent(index)"/></span>
            </div>
            <a-form-model-item
                label="内容类型"
                v-bind="formItemLayoutGrass"
            >
              <a-radio-group
                  v-model="discourse_form.contents[index].type"
                  @change="onChangeGroup(index)"
              >
                <a-radio-button
                    v-for="(item, index) in content_type_list"
                    :key="index"
                    :value="item.id"
                >
                  {{ item.name }}
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
            <!-- 文字 -->
            <a-form-model-item
                label="文字"
                v-bind="formItemLayoutGrass"
                v-if="discourse_form.contents[index].type === 1"
                :prop="'contents.' + index + '.text'"
                :rules="rules.check_duration"
                :required="true"
            >
              <a-textarea
                  v-model="discourse_form.contents[index].text"
                  placeholder="请输入备注"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  :maxLength="500"
              />
            </a-form-model-item>
            <!-- 视频链接 -->
            <div>
              <a-form-model-item
                  label="视频"
                  extra="支持扩展名：.mp4 大小不能超过10M"
                  v-bind="formItemLayoutGrass"
                  v-show="discourse_form.contents[index].type === 8"
              >
                <vod-base-upload
                    :accept-list="accept_video_list"
                    :upload-title="''"
                    :has-file-list="discourse_form.contents[index].accept_video_list"
                    :params-data="vod_params_data"
                    :client-id="client_id"
                    @handleUrl="handleUrlVideo(arguments, 'video',index)"
                    @handlePreview="handlePreviewOpenVideo(arguments)"
                />
                <!--  <v-upload
                      accept-list=".mp4"
                      :has-file-list="discourse_form.contents[index].video_list"
                      :limit-size="10"
                      :is-open-preview="true"
                      :suffix-list="['mp4']"
                      @handleUrl="handleUrl(arguments, 'video', index)"
                      @handlePreview="handlePreviewOpen(arguments)"
                  />-->
              </a-form-model-item>
              <a-form-model-item
                  label="网页标题"
                  v-bind="formItemLayoutGrass"
                  v-if="discourse_form.contents[index].type === 8"
              >
                <a-input
                    v-model="discourse_form.contents[index].file_title"
                    placeholder="请输入标题"
                    :maxLength="50"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="介绍"
                  v-bind="formItemLayoutGrass"
                  v-if="discourse_form.contents[index].type === 8"
              >
                <a-textarea
                    v-model="discourse_form.contents[index].remark"
                    placeholder="请输入"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    :maxLength="500"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="URL"
                  v-bind="formItemLayoutGrass"
                  v-if="discourse_form.contents[index].type === 8"
              >
                <div style="display: flex;align-items: center">
                  <a-input
                      v-model="discourse_form.contents[index].video_page_url"
                      placeholder="https://www.xxxxx.com?xxx"
                      :maxLength="250"
                      disabled
                  />
                  <a-button key='submit' type='link' id='copy_text'
                            :data-clipboard-text='discourse_form.contents[index].video_page_url'
                            @click='handleOkCopyUrl'>复制
                  </a-button>
                </div>
                <p class="video_url_text">视频上传完自动生成URL</p>
              </a-form-model-item>
              <a-form-model-item
                  label="标题"
                  v-bind="formItemLayoutGrass"
                  v-if="discourse_form.contents[index].type === 8"
                  :prop="'contents.' + index + '.title'"
                  :rules="rules.check_title"
                  :required="true"
              >
                <a-input
                    v-model="discourse_form.contents[index].title"
                    placeholder="请输入标题"
                    :maxLength="50"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="描述"
                  v-bind="formItemLayoutGrass"
                  v-if="discourse_form.contents[index].type === 8"
                  :prop="'contents.' + index + '.description'"
                  :rules="rules.check_desc"
                  :required="true"
              >
                <a-textarea
                    v-model="discourse_form.contents[index].description"
                    placeholder="请输入描述"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    :maxLength="500"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="封面"
                  extra="支持扩展名：.png,.jpg,.jpeg"
                  v-bind="formItemLayoutGrass"
                  v-if="discourse_form.contents[index].type === 8"
              >
                <v-upload
                    :accept-list="'.png,.jpg,.jpeg'"
                    :has-file-list="discourse_form.contents[index].cover_list"
                    :limit-size="2"
                    @handleUrl="handleUrl(arguments, 'cover', index)"
                    @handlePreview="handlePreviewOpen(arguments)"
                />
              </a-form-model-item>
            </div>
            <!-- 网页 -->
            <div
                v-if="discourse_form.contents[index].type === 2"
            >
              <a-form-model-item
                  label="标题"
                  v-bind="formItemLayoutGrass"
                  :prop="'contents.' + index + '.title'"
                  :rules="rules.check_title"
                  :required="true"
              >
                <a-input
                    v-model="discourse_form.contents[index].title"
                    placeholder="请输入标题"
                    :maxLength="50"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="链接"
                  v-bind="formItemLayoutGrass"
                  :prop="'contents.' + index + '.web_url'"
                  :rules="rules.check_url"
                  :required="true"
              >
                <a-input
                    v-model="discourse_form.contents[index].web_url"
                    placeholder="请输入链接"
                    :maxLength="250"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="描述"
                  v-bind="formItemLayoutGrass"
                  :prop="'contents.' + index + '.description'"
                  :rules="rules.check_desc"
                  :required="true"
              >
                <a-textarea
                    v-model="discourse_form.contents[index].description"
                    placeholder="请输入描述"
                    :auto-size="{ minRows: 3, maxRows: 5 }"
                    :maxLength="500"
                />
              </a-form-model-item>
              <a-form-model-item
                  label="封面"
                  extra="支持扩展名：.png,.jpg,.jpeg"
                  v-bind="formItemLayoutGrass"
              >
                <v-upload
                    :accept-list="'.png,.jpg,.jpeg'"
                    :has-file-list="discourse_form.contents[index].cover_list"
                    :limit-size="2"
                    @handleUrl="handleUrl(arguments, 'cover', index)"
                    @handlePreview="handlePreviewOpen(arguments)"
                />
              </a-form-model-item>
            </div>
            <!-- 图片 -->
            <a-form-model-item
                label="文件"
                extra="支持扩展名：.png,.jpg,.jpeg"
                v-bind="formItemLayoutGrass"
                v-show="discourse_form.contents[index].type === 3"
            >
              <v-upload
                  :accept-list="'.png,.jpg,.jpeg'"
                  :has-file-list="discourse_form.contents[index].img_list"
                  :limit-size="2"
                  @handleUrl="handleUrl(arguments, 'img', index)"
                  @handlePreview="handlePreviewOpen(arguments)"
              />
            </a-form-model-item>
            <!--  &lt;!&ndash; PDF &ndash;&gt;
              <a-form-model-item
                  label="文件"
                  extra="支持扩展名：.pdf"
                  v-bind="formItemLayoutGrass"
                  v-show="discourse_form.contents[index].type === 4"
              >
                <v-upload
                    :accept-list="'.pdf'"
                    :has-file-list="discourse_form.contents[index].pdf_list"
                    :limit-size="20"
                    :is-open-preview="true"
                    :suffix-list="['pdf']"
                    @handleUrl="handleUrl(arguments, 'pdf', index)"
                    @handlePreview="handlePreviewOpen(arguments)"
                />
                &lt;!&ndash; <div style="font-size:16px;">{{PDFname}}</div> &ndash;&gt;
              </a-form-model-item>-->
            <a-form-model-item
                label="文件名称"
                v-bind="formItemLayoutGrass"
                v-show="discourse_form.contents[index].type === 4"
            >
              <div style="font-weight:600">{{ discourse_form.contents[index].PDFname }}</div>
            </a-form-model-item>
            <!-- WORD -->
            <a-form-model-item
                label="文件"
                extra="支持扩展名：.pdf.wps;.wpt;.doc;.dot;.docx;.doct;.docm;.dotm"
                v-bind="formItemLayoutGrass"
                v-show="discourse_form.contents[index].type === 5 || discourse_form.contents[index].type === 4"
            >
              <v-upload
                  accept-list=".pdf,.wps,.wpt,.doc,.dot,.docx,.doct,.docm,.dotm"
                  :has-file-list="discourse_form.contents[index].doc_list"
                  :limit-size="20"
                  :is-open-preview="true"
                  :suffix-list="['.pdf','wps','wpt','doc','dot','docx','doct','docm','dotm']"
                  @handleUrl="handleUrl(arguments, 'doc', index)"
                  @handlePreview="handlePreviewOpen(arguments)"
              />
            </a-form-model-item>
            <a-form-model-item
                label="文件名称"
                v-bind="formItemLayoutGrass"
                v-show="discourse_form.contents[index].type === 5 || discourse_form.contents[index].type === 4"
            >
              <div style="font-weight:600">{{ discourse_form.contents[index].DOCname }}</div>
            </a-form-model-item>
            <!-- 视频 -->
            <a-form-model-item
                label="文件"
                extra="支持扩展名：.mp4 大小不能超过10M"
                v-bind="formItemLayoutGrass"
                v-show="discourse_form.contents[index].type === 6"
            >
              <v-upload
                  accept-list=".mp4"
                  :has-file-list="discourse_form.contents[index].video_list"
                  :limit-size="10"
                  :is-open-preview="true"
                  :suffix-list="['mp4']"
                  @handleUrl="handleUrl(arguments, 'video', index)"
                  @handlePreview="handlePreviewOpen(arguments)"
              />
            </a-form-model-item>
            <a-form-model-item
                label="文件名称"
                v-bind="formItemLayoutGrass"
                v-show="discourse_form.contents[index].type === 6"
            >
              <div style="font-weight:600">{{ discourse_form.contents[index].VIDEOname }}</div>
            </a-form-model-item>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
        :visible="preview_visible"
        :footer="null"
        @cancel="handlePreviewCancel"
        width="480px"
    >
      <img class="preview" :src="preview_src"/>
    </a-modal>

    <preview-modal
        :visible="preview_visible1"
        :preview-src="preview_src1"
        @cancel="handlePreviewCancel"
    />
  </div>
</template>

<script>
import utils from '@/common/util.js';
import vodBaseUpload from '@/components/VodBaseUpload/vodBaseUpload';
import _base_https from '@/api/base_api';
import previewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import Clipboard from 'clipboard';

let defaultQuery = {
  group_id: '',
  keyword: '',
  user_id: 'aftc'
};

//检验文本
let checkDuration = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写分镜时长'));
  } else {
    callback();
  }
};
// 标题
let checkTitle = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写分享标题'));
  } else {
    callback();
  }
};
// 链接
let checkUrl = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写分镜时长'));
  } else {
    callback();
  }
};
// 描述
let checkDesc = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请填写分享描述'));
  } else {
    callback();
  }
};

export default {
  data () {
    return {
      file_last: '',
      group_list: [],
      type_title: '新建分组',
      group_visible: false,
      select_id: 0,
      group_form: {},
      group_rules: {
        name: [
          {required: true, message: '请填写分组名称', trigger: 'blur'},
          {min: 0, max: 6, message: '名称不能超过6个字', trigger: 'blur'}
        ]
      },
      layout: {
        labelCol: {span: 6},
        wrapperCol: {span: 18}
      },
      formItemLayoutGrass: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6}
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 17}
        }
      },
      is_submit: false,
      active_group_index: 'all',
      query: _.cloneDeep(defaultQuery),
      pagination: {pageSize: 10},
      discourse_title: '新建话术',
      discourse_visible: false,
      discourse_form: {
        contents: [
          {type: 1, cover_list: [], img_list: [], pdf_list: [], doc_list: [], video_list: [], accept_video_list: []}
        ]
      },
      discourse_rules: {
        group_id: [
          {required: true, message: '请选择分组', trigger: 'change'}
        ],
        word_title: [
          {required: true, message: '请填写话术标题', trigger: 'blur'}
        ]
      },
      content_type_list: [
        {id: 1, name: '文字'},
        {id: 8, name: '视频链接'},
        {id: 2, name: '网页'},
        {id: 3, name: '图片'},
        // {id: 4, name: ''},
        {id: 5, name: 'PDF/WORD'},
        {id: 6, name: '视频'}
      ],
      preview_visible: false,
      preview_src: '',
      suffix_list: ['pdf'],
      thumb_url: 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-6-4/PDF.png',
      rules: {
        check_duration: [
          {validator: checkDuration, trigger: 'change'}
        ],
        check_title: [
          {validator: checkTitle, trigger: 'change'}
        ],
        check_url: [
          {validator: checkUrl, trigger: 'change'}
        ],
        check_desc: [
          {validator: checkDesc, trigger: 'change'}
        ]
      },
      accept_video_list: '.mp4',
      product_file_arr: [],
      vod_params_data: null,
      client_id: 'fhl-admin',
      preview_visible1: false,
      preview_src1: '',
      copyUrl: ''
    };
  },
  components: {
    vodBaseUpload,
    previewModal
  },
  computed: {
    columns () {
      return [
        {title: "标题", dataIndex: "title", align: 'center', ellipsis: true},
        {title: "创建人", dataIndex: "user_name", align: 'center', width: 80},
        {title: "类型", dataIndex: "type_name", align: 'center', width: 120},
        {title: "创建时间", dataIndex: "ctime", align: 'center', width: 100},
        {title: "操作", align: 'center', scopedSlots: {customRender: "operation"}, width: 120}
      ];
    }
  },
  mounted () {
    this.getVodData();
    this.getGroupList();
    this.$on("init", this.init);
  },
  methods: {
    //获取分组列表
    getGroupList () {
      new Promise((resolve, reject) => {
        resolve(this.$api.core.Discourse.allGroupList());
      }).then(res => {
        this.group_list = res;
      }).catch(res => {
        this.$message.error('获取分组列表失败');
      });
    },
    //获取列表
    init (options = {}) {
      this.$nextTick(() => {
        this.$refs.table.load();
      });
    },
    remote (qp) {
      return this.$api.core.Discourse.discourseList(qp);
    },
    //打开分组弹窗
    openGroupModal (id, name) {
      if (id) {
        this.type_title = '修改分组';
        this.select_id = id;
      } else {
        this.type_title = '新建分组';
        this.select_id = 0;
      }

      this.group_visible = true;
      this.$set(this.group_form, 'name', name);
    },
    //关闭分组弹窗
    handleGroupCancel () {
      this.group_visible = false;
    },
    //新增/修改分组
    changeGroup () {
      this.$refs.group_form.validate((valid, obj) => {
        if (valid) {
          this.is_submit = true;
          let params = {
            name: this.group_form.name,
            id: Number(this.select_id) ? this.select_id : undefined,
            user_id: 'aftc'
          };
          new Promise((resolve, reject) => {
            resolve(this.$api.core.Discourse.saveGroup(params));
          }).then(res => {
            this.is_submit = false;

            let index = this.active_group_index;
            if (index !== 'all') {
              this.$set(this.group_list[index], 'name', this.group_form.name);
            }
            this.$message.success('保存成功');
            this.handleGroupCancel();
            this.getGroupList();
          }).catch(res => {
            this.is_submit = false;
            this.$message.error('保存失败');
          });
        }
      });
    },
    //选择分组
    onSelectGroup (id, index) {
      this.active_group_index = index;
      this.query.group_id = id;
      this.init();
    },
    //删除分组
    delGroup (id) {
      let _this = this;

      this.$confirm({
        title: '删除分组',
        content: h => <div><span class="red">该分组无法恢复</span>，你还要继续吗？</div>,
        okText: '确定',
        cancelText: '取消',
        onOk () {
          new Promise((resolve, reject) => {
            resolve(_this.$api.core.Discourse.delGroup(id));
          }).then(res => {
            _this.getGroupList();
            _this.query.group_id = '';
            _this.init();
            _this.$message.success('删除该分组成功');
          }).catch(res => {
            _this.$message.error('删除该分组失败');
          });
        }
      });
    },
    //搜索
    onSearch (value) {
      this.query.keyword = value;
      this.init();
    },
    //打开话术弹窗
    openDiscourseModal (id) {
      // this.discourse_form.contents[index].PDFname =''
      if (id) {
        this.discourse_title = '修改话术';
        this.getDiscourseDetail(id);
      } else {
        this.discourse_title = '新建话术';
      }
      this.discourse_visible = true;
    },
    //关闭话术弹窗
    handleDiscourseCancel () {
      this.discourse_visible = false;
      this.discourse_form = {
        contents: [
          {type: 1, cover_list: [], img_list: [], pdf_list: [], doc_list: [], video_list: [], accept_video_list: []}
        ]
      };
    },
    //删除话术
    delDiscourse (id) {
      let _this = this;

      this.$confirm({
        title: '删除话术',
        content: h => <div><span class="red">该话术无法恢复</span>，你还要继续吗？</div>,
        okText: '确定',
        cancelText: '取消',
        onOk () {
          new Promise((resolve, reject) => {
            resolve(_this.$api.core.Discourse.delDiscourse(id));
          }).then(res => {
            _this.init();
            _this.$message.success('删除该话术成功');
          }).catch(res => {
            _this.$message.error('删除该话术失败');
          });
        }
      });
    },
    //获取话术详情
    getDiscourseDetail (id) {
      new Promise((resolve, reject) => {
        resolve(this.$api.core.Discourse.discourseDetail(id));
      }).then(res => {
        this.$set(this, 'discourse_form', res);
        console.log(this.discourse_form.contents);
        let contents = this.discourse_form.contents;

        res.contents.map((item, index) => {
          let type = item.type;
          switch (type) {
            case 1:
              this.$set(contents[index], 'cover_list', []);
              this.$set(contents[index], 'img_list', []);
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', []);
              this.$set(contents[index], 'video_list', []);
              this.$set(contents[index], 'accept_video_list', []);
              break;
            case 2:
              this.$set(contents[index], 'cover_list', this.hasFileArr(item.file_oss_url, 'cover', index));
              this.$set(contents[index], 'img_list', []);
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', []);
              this.$set(contents[index], 'video_list', []);
              this.$set(contents[index], 'accept_video_list', []);
              break;
            case 3:
              this.$set(contents[index], 'cover_list', []);
              this.$set(contents[index], 'img_list', this.hasFileArr(item.file_oss_url, 'img', index));
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', []);
              this.$set(contents[index], 'video_list', []);
              this.$set(contents[index], 'accept_video_list', []);
              break;
            case 4:
              /* this.$set(contents[index], 'cover_list', []);
               this.$set(contents[index], 'img_list', []);
               this.$set(contents[index], 'pdf_list', this.hasFileArr(item.file_oss_url, 'pdf', index));
               this.getFileName(item.file_oss_url, index, 'PDFname');
               this.$set(contents[index], 'doc_list', []);
               this.$set(contents[index], 'video_list', []);*/
              this.$set(contents[index], 'cover_list', []);
              this.$set(contents[index], 'img_list', []);
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', this.hasFileArr(item.file_oss_url, 'doc', index));
              this.$set(contents[index], 'video_list', []);
              this.$set(contents[index], 'accept_video_list', []);
              this.$set(contents[index], 'type', 5);
              this.getFileName(item.file_oss_url, index, 'DOCname');
              break;
            case 5:
              this.$set(contents[index], 'cover_list', []);
              this.$set(contents[index], 'img_list', []);
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', this.hasFileArr(item.file_oss_url, 'doc', index));
              this.$set(contents[index], 'video_list', []);
              this.$set(contents[index], 'accept_video_list', []);
              this.getFileName(item.file_oss_url, index, 'DOCname');
              break;
            case 6:
              this.$set(contents[index], 'cover_list', []);
              this.$set(contents[index], 'img_list', []);
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', []);
              this.$set(contents[index], 'video_list', this.hasFileArr(item.file_oss_url, 'video', index));
              this.getFileName(item.file_oss_url, index, 'VIDEOname');
              break;
            case 8:
              this.$set(contents[index], 'cover_list', []);
              this.$set(contents[index], 'img_list', []);
              this.$set(contents[index], 'pdf_list', []);
              this.$set(contents[index], 'doc_list', []);
              this.$set(contents[index], 'video_list', []);
              this.$set(contents[index], 'cover_list', this.hasFileArr(item.file_oss_url, 'cover', index));
              this.$set(contents[index], 'accept_video_list', this.hasFileArr(item.video_url, 'video', index));
              this.getFileName(item.file_oss_url, index, 'VIDEOname');
              break;
          }
        });
      }).catch(res => {
        this.$message.error('获取话术详情失败');
      });
    },
    //话术 - 分组 - 筛选
    filterOption (input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //提交话术内容
    submitContent () {
      this.$refs.discourse_form.validate((valid, obj) => {
        let check_picture = false
        this.discourse_form.contents.forEach(item=>{
                if(item.type==8){
                  console.log('item',item)
                    if(!item.cover_list.length||!item.accept_video_list.length){
                        this.$message.error('请上传视频与封面图片！')
                        this.is_submit = false
                        check_picture = true
                    }
                }
                if(item.type==2){
                    if( !item.cover_list.length){
                        this.$message.error('请上传图片！')
                        this.is_submit = false
                        check_picture = true
                    }
                }
                if(item.type==3){
                    if( !item.img_list.length){
                        this.$message.error('请上传图片！')
                        this.is_submit = false
                        check_picture = true
                    }
                }
                if(item.type==4){
                    if(!item.pdf_list.length){
                        this.$message.error('请上传文件！')
                        this.is_submit = false
                        check_picture = true
                    }
                }
        })
        if(check_picture) return;
        if (valid) {
          this.is_submit = true;
          let contents = [];
          this.discourse_form.contents.map((item, index) => {
            item.file_last = '';
            switch (item.type) {
              case 1:
                contents.push(
                    {
                      type: item.type,
                      text: item.text,
                      id: item.id
                    }
                );
                break;
              case 2:
                contents.push(
                    {
                      type: item.type,
                      title: item.title,
                      web_url: item.web_url,
                      description: item.description,
                      file_oss_url: item.cover_list.length > 0 ? item.cover_list[0].url : undefined,
                      id: item.id
                    }
                );
                break;
              case 3:
                contents.push(
                    {
                      type: item.type,
                      file_oss_url: item.img_list.length > 0 ? item.img_list[0].url : undefined,
                      id: item.id
                    }
                );
                break;
              case 4:
                contents.push(
                    {
                      type: item.type,
                      file_oss_url: item.pdf_list.length > 0 ? item.pdf_list[0].url : undefined,
                      id: item.id
                    }
                );
                break;
              case 5:
                item.file_last = item && item.file_oss_url.substring(item.file_oss_url.lastIndexOf("."));
                if (item.file_last == '.pdf') {
                  contents.push(
                      {
                        type: 4,
                        file_oss_url: item.doc_list.length > 0 ? item.doc_list[0].url : undefined,
                        id: item.id
                      }
                  );
                } else {
                  contents.push(
                      {
                        type: 5,
                        file_oss_url: item.doc_list.length > 0 ? item.doc_list[0].url : undefined,
                        id: item.id
                      }
                  );
                }
                break;
              case 6:
                contents.push(
                    {
                      type: item.type,
                      file_oss_url: item.video_list.length > 0 ? item.video_list[0].url : undefined,
                      id: item.id
                    }
                );
                break;
              case 8:
                contents.push(
                    {
                      type: item.type,
                      title: item.title,
                      description: item.description,
                      file_oss_url: item.cover_list.length > 0 ? item.cover_list[0].url : undefined,
                      video_page_url: item.video_page_url,
                      video_id: item.video_id,
                      file_title: item.file_title,
                      remark: item.remark,
                      video_file_id: item.video_file_id,
                      video_url: item.video_url,
                      id: item.id
                    }
                );
                break;
            }
          });

          let params = {
            group_id: this.discourse_form.group_id,
            template_id: this.discourse_form.template_id,
            word_title: this.discourse_form.word_title,
            contents: contents,
            user_id: 'aftc'
          };
          console.log(params);
          new Promise((resolve, reject) => {
            resolve(this.$api.core.Discourse.saveDiscourse(params));
          }).then(res => {
            this.is_submit = false;
            this.handleDiscourseCancel();
            if (res === 'success') {
              this.init();
              this.$message.success('保存成功');
            } else {
              this.$message.error('保存失败');
            }
          }).catch(res => {
            this.is_submit = false;
            this.$message.error('保存失败');
          });
        }
      });
    },
    //添加话术内容
    addContent () {
      if (this.discourse_form.contents.length < 10) {
        this.discourse_form.contents.push({
          type: 1,
          cover_list: [],
          img_list: [],
          pdf_list: [],
          doc_list: [],
          video_list: []
        });
      } else {
        this.$message.error('当前话术内容过多，请拆分话术');
      }

    },
    //话术 - 删除内容
    delContent (index) {
      if(this.discourse_form.contents.length ==1 ){
        this.$message.warning('必须存在一个内容信息')
        return ;
      }
      this.discourse_form.contents.splice(index, 1);
    },
    //显示对应的素材链接
    handleUrl (data, type, index) {
      let res = data[0];
      let url = !res ? '' : res.response.url;
      let file_arr = this.hasFileArr(url, type, index);
      switch (type) {
        case 'cover':
          this.$set(this.discourse_form.contents[index], 'cover_list', file_arr);
          break;
        case 'img':
          this.$set(this.discourse_form.contents[index], 'img_list', file_arr);
          break;
        case 'pdf':
          this.$set(this.discourse_form.contents[index], 'pdf_list', file_arr);
          this.getFileName(url, index, 'PDFname');
          break;
        case 'doc':
          this.$set(this.discourse_form.contents[index], 'doc_list', file_arr);
          this.getFileName(url, index, 'DOCname');
          break;
        case 'video':
          this.$set(this.discourse_form.contents[index], 'video_list', file_arr);
          this.getFileName(url, index, 'VIDEOname');
          break;
      }
      this.discourse_form.contents[index].file_oss_url = url;
    },
    hasFileArr (url, type, index) {
      console.log('想知道这个url', url);
      if (type == 'pdf') {
        this.suffix_list = ['pdf'];
        this.thumb_url = 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-6-4/PDF.png';
      } else if (type == 'doc') {
        this.suffix_list = ['pdf', 'wps', 'wpt', 'doc', 'dot', 'docx', 'doct', 'docm', 'dotm'];
        let urls = url ? url.substring(url.lastIndexOf(".")) : null;
        if (urls && urls == '.pdf') {
          this.thumb_url = 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-6-4/PDF.png';
        } else {
          this.thumb_url = 'http://fhl-ai-video-test.oss-cn-hangzhou.aliyuncs.com/image/2021-8-9/doc.png';
        }
      }
      if (url) {
        return [
          {
            uid: index,
            name: type + index,
            status: 'done',
            url: url,
            thumbUrl: utils.fileSuffixTypeUtil(url, this.suffix_list) ? this.thumb_url : url,
            editor: true
          }
        ];
      } else {
        return [];
      }
    },
    // 获取pdf 名称
    getFileName (value, index, name) {
      if (!value) {
        this.discourse_form.contents[index][name] = '';
        return;
      }
      let url = decodeURIComponent(value);
      let arr = url.split('/');
      this.discourse_form.contents[index][name] = arr[arr.length - 1];
    },
    //预览
    handlePreviewOpen (data) {
      this.preview_visible = true;
      this.preview_src = data[0];
    },
    //关闭预览
    handlePreviewCancel () {
      this.preview_src = '';
      this.preview_visible = false;
      this.preview_src1 = '';
      this.preview_visible1 = false;
    },
    //获取凭证数据
    getVodData () {
      _base_https.getTemplateData({businessModulerId: 6}).then(res => {
        if (res.code === 200) {
          this.vod_params_data = res.data;
        } else {
          console.error('获取模板cateId数据失败');
        }
      }).catch(res => {
        console.error('获取模板cateId数据失败');
      });
    },
    handleUrlVideo (data, type, index) {
      let res = data[0];
      let url = !res ? '' : res.xhr;
      let video_id = res && res.response.videoId ? res.response.videoId : '';
      let env = '';
      let file_arr = this.hasFileArr(url, type, index);
      console.log(process.env);
      switch (process.env.VUE_APP_ENV) {
        case 'dev':
          env = 'https://aft-wechat-tool.afanticar.net';
          break;
        case 'uat':
          env = 'https://aft-wechat-tool.afanticar.cn';
          break;
        case 'pro':
          env = 'https://aft-wechat-tool.afanticar.com';
          break;
      }
      let previous_page = `${ env }/video_player?video_id=${ video_id }&previous_page=vod`;
      console.log(previous_page);
      switch (type) {
        case 'video':
          this.$set(this.discourse_form.contents[index], 'video_id', video_id);
          this.$set(this.discourse_form.contents[index], 'video_url', url);
          this.$set(this.discourse_form.contents[index], 'video_page_url', previous_page);
          this.$set(this.discourse_form.contents[index], 'accept_video_list', file_arr);
          // this.$set(this, 'copyUrl', previous_page);
          break;
      }
    },
    handlePreviewOpenVideo (data) {
      this.preview_visible1 = true;
      this.preview_src1 = data[0];
    },
    handleOkCopyUrl () {
      const clipboard = new Clipboard('#copy_text');
      clipboard.on('success', e => {
        this.$message.success('复制成功');
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.error('该浏览器不支持自动复制');
        clipboard.destroy(); // 释放内存
      });
    },
    onChangeGroup(index){
        this.$set(this.discourse_form.contents[index], 'cover_list', []);
        this.$set(this.discourse_form.contents[index],'description','')
        this.$set(this.discourse_form.contents[index],'title','')
        this.$set(this.discourse_form.contents[index],'web_url','')
    }
  }
};
</script>

<style lang="less" scoped>
.space-40 {
  margin: 20px 0;
  border-top: 1px dashed #eee;
}

.red {
  color: #e6767f;
}

.green {
  color: #71c1a5;
}

.ml-10p {
  margin-left: 10px;
}

.group-wrap {
  width: 100%;
  min-height: calc(100vh - 370px);

  .title {
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    background-color: rgba(24, 144, 255, 0.05);
  }

  .group-box {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    list-style: none;
    cursor: pointer;
    border-top: 1px solid #eee;
    background-color: rgba(24, 144, 255, 0.05);

    &:hover .action {
      display: block;
    }

    &.active {
      background-color: rgba(24, 144, 255, 0.1);
    }
  }

  .name {
    // width: 160px;
  }

  .action {
    display: none;
  }

  .icon {
    padding: 2px 5px;
  }
}

.content-wrap {
  overflow: hidden;
  background-color: rgba(24, 144, 255, 0.05);
  border-radius: 4px;

  .top-box {
    padding: 10px 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #ddd;
    font-weight: bold;
  }
}

.preview {
  width: 100%;
}

.video_url_text {
  color: #f5a60b;
}
</style>
